.shopping__cart__card {
  box-shadow: 7px 8px 5px -6px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 7px 8px 5px -6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 7px 8px 5px -6px rgba(0, 0, 0, 0.28);
  overflow: hidden;
  height: 100px;
  width: 100%;
  border: 1px solid rgb(227, 218, 218);
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  //card image
  .cart__card__img {
    height: 100%;
    width: 28%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  //card Name and pricea
  .prdName__prdPrice {
    // border: 1px solid black;
    height: 100%;
    width: 38%;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    .nom {
      font-size: 15px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .prix {
      font-size: 13px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  //Edit button
  .edit__wrapper {
    margin-left: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 8px;
    border-radius: 10px;
  }
  //Delete button
  position: relative;
  .cart__delete {
    // border: 1px solid black;
    color: #c71414;
    height: 20px;
    width: 20px;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }
  //quantity control
  .container {
    // border: 1px solid red;
    overflow: hidden;
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    padding: 0;
    .qte__wrapper {
      padding: 0;
      height: 100%;
      width: 80%;
      // background: #f6efef;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid rgb(184, 174, 174);
      .qte__select {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;
        .ctrl__icon {
          background: #fff;
          height: 40%;
          width: 100%;
          color: #000;
          font-size: 16px;
          cursor: pointer;
        }
        input {
          text-align: center;
          padding: 0;
          // margin: auto;
          font-size: 18px;
          height: 60%;
          width: 100%;
          border-radius: none;
          border-bottom:1px solid rgb(227, 218, 218);
          border-top: 1px solid rgb(227, 218, 218);
          &:focus {
            outline: none !important;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          border: none;
          margin: 0;
        }
      }
    }

    .shop__add__icon {
      cursor: pointer;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin-left: 5px;
      font-size: 24px;
      font-weight: bold;
      border-radius: 5px;
      color: #fff;
    }
  }
}

.detail__wrapper {
  //a revoir
  padding: 100px 10px 10px 10px;
  // @media (max-width: 1020px) {
  //   padding: 3% 6%;
  // }
  //
  height: 100vh;
  display: flex;
  .img_section__wrapper {
    //border: 1px solid black;
    width: 50%;
    max-height: 550px;

    //SWIPPER style
    .swiper {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: start;
    }
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      font-weight: 500;
      color: #000;
      opacity: 1;
      background: #fff;
    }
    .swiper-pagination-bullet-active {
      color: #fff;
      background: #007aff;
    }
    //SWIPPER style end
  }

  .details__section__wapper {
    // border: 1px solid black;
    width: 48%;
    margin-left: 30px;
    .product__name {
      color: #000000dd;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .descrip__title {
      color: #000000dd;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
      //text-decoration: underline;
    }
    .product__descrip {
      color: #000000dd;
      font-size: 15px;
      text-align: justify;
      margin-bottom: 20px;
    }
    .product__stock {
      color: #000000dd;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
      span {
        color: #000000dd;
        font-size: 18px;
        font-weight: normal;
      }
    }
    .container {
      // border: 1px solid black;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .product__price {
        border-radius: 5px;
        width: 50%;
        color: #000000dd;
        font-size: 18px;
        font-weight: 500;
        padding: 10px 0;
        span {
          color: #000000dd;
          font-size: 18px;
          font-weight: normal;
        }
        //  border: 1px solid black;
      }
      .quantity__wrapper {
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        color: #000000dd;
        .qte__wrapper {
          border: 1px solid rgba(229, 232, 239, 0.706);
          border-radius: 5px;
          width: 65%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ctrl__icon {
            background: rgba(219, 206, 206, 0.706);
            width: 20%;
            height: 100%;
            color: #000000dd;
            font-size: 15px;
            cursor: pointer;
          }
          input {
            text-align: center;
            margin: auto;
            font-size: 18px;
            width: 60%;
            // height: 30px;
            padding: 6px;
            border-radius: none;
            &:focus {
              outline: none !important;
            }
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            border: none;
            margin: 0;
          }
        }
        .shop__add__icon {
          cursor: pointer;
          margin-left: 10px;
          width: 30%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 10px;
          font-size: 12px;
          font-weight: bold;
          border-radius: 5px;
          color: #fff;
        }
      }
    }
    .buttons__wrapper {
      display: flex;
      align-items: start;
      // justify-content: space-between;
      flex-direction: column;
      button {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 15px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 5px;
        color: #fff;
      }
      .btn_icon {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}

// ---------------------------------------------Responsive----------------------------------------------------
@media (max-width: 1000px) {
  .detail__wrapper {
    //a revoir
    padding: 90px 8px 8px 8px;
    //
    height: 100vh;
    display: flex;
    flex-direction: column;
    .img_section__wrapper {
      //border: 1px solid black;
      width: 100%;
      max-height: 550px;
      @media (max-width: 750px) {
        max-height: 400px;
      }
      //SWIPPER style
      .swiper {
        width: 100%;
        height: 100%;
      }
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: start;
      }
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        color: #000;
        opacity: 1;
        background: #fff;
      }
      .swiper-pagination-bullet-active {
        color: #fff;
        background: #007aff;
      }
      //SWIPPER style end
    }

    .details__section__wapper {
      // border: 1px solid black;
      width: 100%;
      margin: 0;
      .product__name {
        color: #000000dd;
        font-size: 25px;
        font-weight: bold;
        margin: 20px 0;
      }
      .descrip__title {
        color: #000000dd;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
        //text-decoration: underline;
      }
      .product__descrip {
        color: #000000dd;
        font-size: 15px;
        text-align: justify;
        margin-bottom: 20px;
      }
      .product__stock {
        color: #000000dd;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        span {
          color: #000000dd;
          font-size: 18px;
          font-weight: normal;
        }
      }
      .container {
        // border: 1px solid black;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        @media (max-width: 550px) {
          flex-direction: column;
          align-items: start;
        }
        justify-content: space-between;
        .product__price {
          border-radius: 5px;
          width: 50%;
          color: #000000dd;
          font-size: 18px;
          font-weight: 500;
          padding: 10px 0;
          span {
            color: #000000dd;
            font-size: 18px;
            font-weight: normal;
          }
          //  border: 1px solid black;
        }
        .quantity__wrapper {
          font-weight: normal;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 10px;
          color: #000000dd;
          .qte__wrapper {
            border: 1px solid rgba(229, 232, 239, 0.706);
            border-radius: 5px;
            width: 65%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ctrl__icon {
              background: rgba(219, 206, 206, 0.706);
              width: 20%;
              height: 100%;
              color: #000000dd;
              font-size: 15px;
              cursor: pointer;
            }
            input {
              text-align: center;
              margin: auto;
              font-size: 18px;
              width: 60%;
              // height: 30px;
              padding: 6px;
              border-radius: none;
              &:focus {
                outline: none !important;
              }
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              border: none;
              margin: 0;
            }
          }
          .shop__add__icon {
            cursor: pointer;
            margin-left: 10px;
            width: 30%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 10px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 5px;
            color: #fff;
          }
        }
      }
      .buttons__wrapper {
        display: flex;
        align-items: start;
        // justify-content: space-between;
        flex-direction: column;
        button {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 15px;
          font-size: 15px;
          font-weight: bold;
          border-radius: 5px;
          color: #fff;
        }
        .btn_icon {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }
}
// --------------------------------------------------------------------------------------------------------

.boutik__modal {
  text-align: center;
  margin-bottom: 10px;
  .card__btk {
    padding: 20px;
    margin: auto;
    border-radius: 8px;
    width: 90%;
    box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.6);
    transition: 1s ease-in-out;
  }

  .card__btk span {
    font-weight: bold;
    color: #171717;
    text-align: center;
    display: block;
    padding-top: 1em;
    font-size: 1em;
  }

  .card__btk .info__btk {
    font-weight: 400;
    color: #171717;
    display: block;
    text-align: center;
    padding-top: 0.1em;
    font-size: 15px;
    margin: 1em;
    margin-top: 1em;
  }

  .card__btk .img__btk {
    width: 150px;
    // height: 150px;
    background: white;
    border-radius: 15px;
    margin: auto;
  }

  .card__btk {
    text-align: center;
    margin-top: 1.8em;
  }

  .card__btk {
    color: white;
    transition: 0.4s ease-in-out;
  }

  .option__btk button {
    margin: auto;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    transition: 0.4s ease-in-out;
  }
  .icn__btn {
    font-size: 24px;
  }

  .option__btk button:hover {
    background: rgb(36, 116, 221);
    color: white;
  }
}

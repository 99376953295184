.buttonContainer {

    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-content: center;
    align-items: center;
    height: 30px;
    margin: 0 0 0 0;
    
}

.button {
    width: 45px;
    height: 20px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    /* --padding-top: 1.75rem;
    --padding-bottom: 1.75rem;
    --padding-start: 1.75rem;
    --padding-end: 1.75rem;  */
}
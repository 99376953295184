.bottom__form__wrapper{
    padding: 8px 15px;
    margin: 80px 0;
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    h1{
        margin: auto;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    input{
        margin: auto;
        margin-bottom: 10px;
        width: 300px;
        border: 1px solid #d2c9c9;
        padding: 10px;
        border-radius: 8px;
    }
    button{
        margin: auto;
        width: 150px;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #fff;
        font-weight: 500;
        border-radius: 8px;
    }
}
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.alice {
  /* background-color: aliceblue;  */
  /* background: rgb(233, 231, 249); */
  /* background: rgb(222,219,230);
  background: linear-gradient(159deg, rgba(222,219,230,1) 0%, rgba(245,244,240,1) 100%); */
  /* background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);
   */
  /* background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%); */
  /* background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%); */
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}
.fat {
  background-color: rgb(255, 255, 255);
}
.navfixe {
  background: #f9f9de;
  position: sticky;
  top: 0;
  z-index: 999;
}

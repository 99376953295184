.shop__Page{
    padding: 10px 10px 10px 10px;
    .other_btn{
        margin-top: 20px;
        // border: 1px solid black;
        padding: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        button{
            // width: 30%;
            padding: 10px 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            color: #fff;
            font-weight: 500;
            border-radius: 8px;
        }
    }
}
.form__body {
  height: 100vh;
  padding: 150px 15px;
  .form__wrapper {
    padding: 50px 30px;
    // height: 55%;
    width: 400px;
    @media (max-width: 700px) {
      width: 90vw;
    }
    background-color: #dbe2e854;
    backdrop-filter: blur(8px);
    margin: auto;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.75);
    h2 {
      margin: 0;
      height: 50px;
      width: 100%;
      color: #000;
      font-size: 20px;
      font-weight: bolder;
    }
    input {
      margin: 50px 0;
      color: #000000c4;
      padding: 10px 20px;
      height: 50px;
      width: 100%;
      border: 1px solid rgb(24, 21, 21);
      border-radius: 5px;
    }
    .sparator{
        margin-top: 40px;
        width: 100%;
        background: gray;
        height: 1px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      font-size: 15px;
      font-weight: bold;
      border-radius: 5px;
      color: #fff;
      width: 100%;
    }
  }
}

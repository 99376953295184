.shopping__cart__wrapper {
  .modal-body {
    overflow: hidden;
    padding: 0;
  }
  .cart__content {
    // border: 1px solid black;
    overflow-y: scroll;
    padding: 5px 8px;
    width: 100%;
    height: 60vh;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #b2aeaee7;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(29 78 216 / 1);
    }
  }
  h5 {
    color: #000000dd;
    font-weight: bold;
  }
  .bottom__ctrl {
    // border-radius: 8px;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;
    p {
      font-size: 18px;
      font-weight: bolder;
      color: #000000dd;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      font-size: 15px;
      font-weight: bold;
      border-radius: 5px;
      color: #fff;
    }
  }
}

.tab__1__wrapper {
  margin-top: 30px;
//   border: 1px solid black;
  .Product__card {
    margin: 35px 0;
    height: 300px;
    width: 220px;
    box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 8px 6px 5px 0px rgba(0, 0, 0, 0.75);
    transition: all 1s ease;
    overflow: hidden;
    border: 1px solid rgb(215, 232, 232);
    border-radius: 8px;
    .card__img__wapper {
      cursor: pointer;
      height: 55%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // &:hover {
        //   transition: all 0.5s ease-in-out;
        //   -webkit-transform: scale(1.03);
        //   transform: scale(1.03);
        // }
      }
    }
    .card__info__wrapper {
      margin: 1% 0;
      padding: 5px;
      height: 27%;
      width: 100%;
      .product__name {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        @media (max-width: 1020px) {
          font-size: 16px;
          text-align: center;
        }
      }
      .price__stock {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1020px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 15px;
          font-weight: 500;
          font-size: 18px;
          @media (max-width: 1020px) {
            font-size: 13px;
          }
        }
      }
    }
    .btn__wrapper {
      position: relative;
      height: 15%;
      width: 100%;
      .shop__icon {
        font-size: 18px;
      }
      .product__card__btn {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 15px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 5px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      //secondary == quantity

      .container {
        // border: 1px solid red;
        overflow: hidden;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .qte__wrapper {
          height: 100%;
          width: 70%;
          font-weight: bold;
          border-radius: 5px;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 8px;
          // background: #f6efef;
          border: 1px solid rgb(184, 174, 174);
          .qte__select {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ctrl__icon {
              width: 40%;
              height: 100%;
              color: #000;
              font-size: 16px;
              cursor: pointer;
            }
            input {
              text-align: center;
              margin: auto;
              font-size: 18px;
              width: 60%;
              height: 100%;
              border-radius: none;
              &:focus {
                outline: none !important;
              }
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              border: none;
              margin: 0;
            }
          }
        }

        .shop__add__icon {
          cursor: pointer;
          height: 100%;
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          font-size: 12px;
          font-weight: bold;
          border-radius: 5px;
          color: #fff;
        }
      }

      //
    }
    position: relative;
    .badge__wapper {
      transition: all 0.5s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid white;
      height: 30px;
      width: 30px;
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 1;
      border-radius: 30%;
      background-color: #2073b854;
      backdrop-filter: blur(8px);
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .like__wapper {
      cursor: pointer;
      transition: all 0.5s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid white;
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 99;
      opacity: 0;
      .like__icon {
        font-weight: 500;
        font-size: 30px;
        color: #d30f0f;
      }
    }
    &:hover {
      .like__wapper {
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid white;
        height: 50px;
        width: 50px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;
        opacity: 1;
        .like__icon {
          font-weight: 500;
          font-size: 30px;
          color: #d30f0f;
        }
      }
    }
  }

  //------------------------------------------------
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
}

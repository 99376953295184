.navbar {
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  box-sizing: border-box;
  // padding-left: 60px;
  // padding-right: 60px;
  width: 100%;
  background: #000000e7;
  color: #fff;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  .logo {
    // border: 1px solid white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
   padding-left: 50px;
    font-size: 30px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .panel {
    margin-left: 10%;
    // border: 1px solid white;
    display: flex;
    align-items: center;
    //---------------------------
    a {
      margin: 0;
      cursor: pointer;
      font-size: 20px;
    }
    li {
      margin-right: 40px;
      .nav-active {
        color: rgb(29 78 216 / 1);
        font-size: 20px;
        text-decoration-line: none;
      }
    }
  }
  .panel__close {
    margin-left: 10%;
    // border: 1px solid white;
    display: flex;
    align-items: center;
    //---------------------------
    a {
      margin-right: 40px;
      cursor: pointer;
      font-size: 20px;
    }
    li {
      margin-right: 40px;
      .nav-active {
        color: rgb(29 78 216 / 1);
        font-size: 20px;
        text-decoration-line: none;
      }
    }
  }
  .fav_drop {
    background: #000000e7;
    width: 300px;
    max-height: 60vh;
    padding: 20px 15px;
    overflow: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }
    // &::-webkit-scrollbar-track {
    //   background: #b2aeaee7;
    // }
    &::-webkit-scrollbar-thumb {
      // background: rgb(29 78 216 / 1);
      background-color: #fff;
    }

    .favorite__box {
      &:hover {
        background: rgb(191 219 254 / 1);
      }
      cursor: pointer;
      background: #f6efef;
      padding: 0 15px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: left;
      border: 1px solid rgb(227, 214, 214);
      height: 60px;
      border-radius: 10px;
      img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-right: 20px;
        border: 1px solid black;
      }
      span {
        font-size: 18px;
        text-transform: capitalize;
      }
    }
    .addicon {
      font-size: 24px;
      font-weight: bold;
      margin-right: 15px;
    }
  }
  .cart__wrapper {
    cursor: pointer;
    position: relative;
    right: 50px;
    .nav__shop__card {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      border-radius: 50%;
      padding: 10px;
      height: 40px;
      width: 40px;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      top: -7px;
      right: -7px;
      font-size: 12px;
      background: rgb(195, 23, 23);
    }
  }
  .hamburger {
    display: none;
  }
}

//------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------

@media (max-width: 1050px) {
  .navbar {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    box-sizing: border-box;
    // width: 100%;
    background: #000000e7;
    color: #fff;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    .logo {
      // border: 1px solid white;
   padding-left: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: start;
      font-size: 25px;
      font-weight: bold;
    }
    .panel {
      transition: all 0.5s ease;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: start;
      height: 100vh;
      width: 290px;
      padding: 20px;
      background-color: #01060aac;
      backdrop-filter: blur(20px);
      position: absolute;
      top: 80px;
      left: 0;
      //---------------------------
      a {
        color: #fff;
        margin: 0;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
      }
      li {
        margin-bottom: 25px;
        .nav-active {
          color: rgb(29 78 216 / 1);
          font-size: 20px;
          text-decoration-line: none;
        }
      }
    }
    .panel__close {
      transition: all 0.5s ease;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: start;
      height: 100vh;
      width: 290px;
      padding: 20px;
      background-color: #01060aac;
      backdrop-filter: blur(20px);
      position: absolute;
      top: 80px;
      left: -1000px;
      //---------------------------
      a {
        color: #fff;
        margin-bottom: 40px;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
      }
      li {
        margin-bottom: 25px;
        .nav-active {
          color: rgb(29 78 216 / 1);
          font-size: 20px;
          text-decoration-line: none;
        }
      }
    }
    .cart__wrapper {
      display: none;
      cursor: pointer;
      position: relative;
      right: 50px;
      .nav__shop__card {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        border-radius: 50%;
        padding: 10px;
        height: 40px;
        width: 40px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        top: -7px;
        right: -7px;
        font-size: 12px;
        background: rgb(195, 23, 23);
      }
    }
    .hamburger {
      display: block;
      .menu-icon {
        zoom: 0.5;
        width: 60px;
        height: 45px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
      }
      .menu-icon span {
        display: block;
        position: absolute;
        height: 5px;
        width: 100%;
        // background: #000000dd;
        background: #fff;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      .menu-icon span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      .menu-icon span:nth-child(2) {
        top: 18px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      .menu-icon span:nth-child(3) {
        top: 36px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      .menu-icon.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
      }

      .menu-icon.open span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      .menu-icon.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 39px;
        left: 8px;
      }
    }
  }
}

// .navbar {
//   //   background: rgba(255, 255, 255, 0.23);
//   //   border-radius: 16px;
//   //   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//   //   backdrop-filter: blur(11px);
//   //   -webkit-backdrop-filter: blur(11px);
//   //   border: 1px solid rgba(255, 255, 255, 0.69);

//   display: flex;
//   align-items: center;
//   //   background: #a88e8e;
//   height: 50px;
//   box-sizing: border-box;
//   padding: 10px 40px;

//   .logo {
//     margin-left: 5px;
//     width: 90%;
//     display: flex;
//     justify-content: center;
//     font-size: 20px;
//     font-weight: bold;
//     color: #000000dd;
//   }
//   .panel__open {
//     transition: all 0.7s ease;
//     // padding: 20px;
//     font-size: 20px;
//     font-weight: bold;
//     background-color: #cad4dc54;
//     backdrop-filter: blur(15px);
//     height: 100vh;
//     width: 500px;
//     position: absolute;
//     z-index: 100;
//     top: 50px;
//     left: 0;
//     p {
//       cursor: pointer;
//     }
//   }
//   .panel__close {
//     margin-left: -5000px;
//     transition: all 0.7s ease;
//     // padding: 20px;
//     font-size: 20px;
//     font-weight: bold;
//     backdrop-filter: blur(11px);
//     height: 100vh;
//     width: 500px;
//     position: absolute;
//     z-index: 100;
//     top: 50px;
//     left: 0;
//     p {
//       cursor: pointer;
//     }
//   }
//   .addicon{
//     font-size: 24px;
//     font-weight: bold;
//     margin-right: 15px;
//   }

//   .favorite__box {
//     cursor: pointer;
//     background: #f6efef;
//     padding: 0 15px;
//     display: flex;
//     align-items: center;
//     justify-content: left;
//     border: 1px solid rgb(227, 214, 214);
//     height: 60px;
//     border-radius: 10px;
//     img {
//       height: 50px;
//       width: 50px;
//       border-radius: 50%;
//       margin-right: 20px;
//       border: 1px solid black;
//     }
//     span {
//       font-size: 18px;
//       text-transform: capitalize;
//     }
//   }
//   .hamburger {
//     width: 3%;
//     display: block;
//     // i {
//     //   font-size: 24px;
//     //   cursor: pointer;
//     //   color: white;
//     // }
//     .menu-icon {
//       zoom: 0.5;
//       width: 60px;
//       height: 45px;
//       position: relative;
//       -webkit-transform: rotate(0deg);
//       -moz-transform: rotate(0deg);
//       -o-transform: rotate(0deg);
//       transform: rotate(0deg);
//       -webkit-transition: 0.5s ease-in-out;
//       -moz-transition: 0.5s ease-in-out;
//       -o-transition: 0.5s ease-in-out;
//       transition: 0.5s ease-in-out;
//       cursor: pointer;
//     }
//     .menu-icon span {
//       display: block;
//       position: absolute;
//       height: 5px;
//       width: 100%;
//       background: #000000dd;
//       border-radius: 9px;
//       opacity: 1;
//       left: 0;
//       -webkit-transform: rotate(0deg);
//       -moz-transform: rotate(0deg);
//       -o-transform: rotate(0deg);
//       transform: rotate(0deg);
//       -webkit-transition: 0.25s ease-in-out;
//       -moz-transition: 0.25s ease-in-out;
//       -o-transition: 0.25s ease-in-out;
//       transition: 0.25s ease-in-out;
//     }

//     .menu-icon span:nth-child(1) {
//       top: 0px;
//       -webkit-transform-origin: left center;
//       -moz-transform-origin: left center;
//       -o-transform-origin: left center;
//       transform-origin: left center;
//     }

//     .menu-icon span:nth-child(2) {
//       top: 18px;
//       -webkit-transform-origin: left center;
//       -moz-transform-origin: left center;
//       -o-transform-origin: left center;
//       transform-origin: left center;
//     }

//     .menu-icon span:nth-child(3) {
//       top: 36px;
//       -webkit-transform-origin: left center;
//       -moz-transform-origin: left center;
//       -o-transform-origin: left center;
//       transform-origin: left center;
//     }

//     .menu-icon.open span:nth-child(1) {
//       -webkit-transform: rotate(45deg);
//       -moz-transform: rotate(45deg);
//       -o-transform: rotate(45deg);
//       transform: rotate(45deg);
//       top: -3px;
//       left: 8px;
//     }

//     .menu-icon.open span:nth-child(2) {
//       width: 0%;
//       opacity: 0;
//     }

//     .menu-icon.open span:nth-child(3) {
//       -webkit-transform: rotate(-45deg);
//       -moz-transform: rotate(-45deg);
//       -o-transform: rotate(-45deg);
//       transform: rotate(-45deg);
//       top: 39px;
//       left: 8px;
//     }
//   }
// }

// @media screen and (max-width: 875px) {}
